import React from "react"

export default function Button(props) {
    return (
        <>
            <a href="https://goo.gl/maps/kEReVrFv5Qqfkp477" className="c-map-link" target="_blank" rel="noopener noreferrer">
                <span className="c-map-link__content">
                    <span className="is-icon">
                        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 21.8637L2.636 15.4997C1.37734 14.241 0.520187 12.6373 0.172928 10.8915C-0.17433 9.14569 0.00390685 7.33609 0.685099 5.69157C1.36629 4.04704 2.51984 2.64144 3.99988 1.65251C5.47992 0.66358 7.21998 0.135742 9 0.135742C10.78 0.135742 12.5201 0.66358 14.0001 1.65251C15.4802 2.64144 16.6337 4.04704 17.3149 5.69157C17.9961 7.33609 18.1743 9.14569 17.8271 10.8915C17.4798 12.6373 16.6227 14.241 15.364 15.4997L9 21.8637ZM13.95 14.0857C14.9289 13.1067 15.5955 11.8594 15.8656 10.5015C16.1356 9.14369 15.9969 7.73626 15.4671 6.45722C14.9373 5.17818 14.04 4.08497 12.8889 3.31583C11.7378 2.54669 10.3844 2.13617 9 2.13617C7.61557 2.13617 6.26222 2.54669 5.11109 3.31583C3.95996 4.08497 3.06275 5.17818 2.53292 6.45722C2.00308 7.73626 1.86442 9.14369 2.13445 10.5015C2.40449 11.8594 3.07111 13.1067 4.05 14.0857L9 19.0357L13.95 14.0857ZM9 11.1357C8.46957 11.1357 7.96086 10.9249 7.58579 10.5499C7.21072 10.1748 7 9.6661 7 9.13566C7 8.60523 7.21072 8.09652 7.58579 7.72145C7.96086 7.34638 8.46957 7.13566 9 7.13566C9.53044 7.13566 10.0391 7.34638 10.4142 7.72145C10.7893 8.09652 11 8.60523 11 9.13566C11 9.6661 10.7893 10.1748 10.4142 10.5499C10.0391 10.9249 9.53044 11.1357 9 11.1357Z" fill="#00050A"/>
                        </svg>
                    </span>
                    <span className="is-text">Google Map</span>
                </span>
            </a>
        </>
    )
}
