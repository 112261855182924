import React from "react"
import Layout from "src/component/layout/layout"
import Button from "src/component/common/button"
import Gmap from "src/component/common/gmap"
import NewsListLatest3posts from "src/component/list/newsListLatest3posts"
import ClientList from "src/component/list/clientList"
import { Seo } from "src/component/common/seo"
import { ImgixGatsbyImage } from '@imgix/gatsby';
import { StaticImage, GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

export default function Company({ data }) {
    const overviewImgPath = withArtDirection(getImage(data.overviewPc.childImageSharp), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.overviewSp.childImageSharp),
        },
    ])
    return (
        <>
            <Layout>
                <div className="p-vision">
                    <div className="p-vision__inner c-inner-primary">
                        <h1 className="p-vision__title">
                            <span className="is-english">DATAFLUCT vision</span>
                            <span className="is-japanese">持続可能な未来を、<br />アルゴリズムの共有で実現する</span>
                        </h1>
                        <div className="p-vision__policy">
                            <p className="p-vision__lead">DATAFLUCTには、サステナブルアルゴリズムの社会実装という経営理念があります。</p>
                            <p className="p-vision__lead">「美しい社会と企業活動が100年後も持続できるアルゴリズム」を社会全体に実装していくことで、<br />
                                「経済や産業だけではなく、企業関係も、社会環境も、地球環境も、ずっとよくなるはずだ」<br />
                                という仮説を立証するために活動をはじめました。</p>
                            <p className="p-vision__lead">アルゴリズムとは、様々な問題に対してその最適解にたどりつくための手続きのことで、<br />
                                経営思想・オペレーション・顧客体験、価値基準そのものに影響を与えていく「計算方法」に<br />
                                他ならないと考えています。</p>
                            <p className="p-vision__lead">この価値基準にも影響を与える計算方法（アルゴリズム）を広く共有し、誰もが実装できるようにすることで、<br />
                                顧客体験も、企業活動も、社会活動もよりよいものになっていくと考えています。</p>
                        </div>
                        <div className="p-vision__message">
                            <figure className="p-vision__message__image">
                                <StaticImage
                                    src="../assets/img/pages/company/message_img01.png"
                                    alt="代表取締役 CEO 久米村 隼人"
                                />
                            </figure>
                            <div className="p-vision__message__text">
                                <p className="p-vision__lead">「データを商いに。」<br />
                                    今、怒涛の環境変化やデジタル技術の発展に伴い、多くの企業は様々な社会課題やDX課題に向き合い、「変化しなければ」と岐路に立たされています。</p>
                                <p className="p-vision__lead">DATAFLUCTは、「データを商いに。」というビジョンを掲げて、<br />
                                    多くの企業の「変化点」に立ち会ってきました。</p>
                                <p className="p-vision__lead">この言葉には、<br />
                                    「すべての企業がデータを有効に活用して、価値のあるビジネスを続けられるように」<br />
                                    「一人でも多くの人が、データサイエンスに出会い、その恩恵が受けられるように」<br />
                                    「どんな企業も社会課題に向かうデータビジネスを切り拓けるように」<br />
                                    という強い思いが込められています。</p>
                                <p className="p-vision__lead">モノを作り、流通させ、販売するという過程で発生するロスも、<br />
                                    組織の成長を妨げている非効率な働き方も、<br />
                                    データを活用すればなくすことができます。</p>
                                <p className="p-vision__lead">私たちは、高度なデータサイエンスの技術と、データを活用する事業開発力、<br />
                                    そして、データの力で持続可能な豊かな世界を実現したいという情熱を持っています。</p>
                                <p className="p-vision__lead">データの世界で、多くの産業を繋げ、<br />
                                    環境、社会、ビジネスそして人に最適な答えを見つけるために。<br />
                                    私たちDATAFLUCTは社会のデータ基盤を構築していきます。</p>
                                <p className="p-vision__lead">2022年8月 久米村隼人</p>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="p-client">
                    <span id="client" className="p-client__anchor"></span>
                    <div className="p-client__inner c-inner-primary">
                        <div className="p-client__title c-title01">
                            <h2 className="p-client__title__text c-title01__text">Customers</h2>
                        </div>
                        <div className="p-client__lead">
                            <p className="p-client__lead__text">様々な業界・業種の企業様とお取引をさせて頂いております。</p>
                            <small className="p-client__lead__annotation">※ 順不同</small>
                        </div>
                        <ClientList />
                    </div>
                </section>
                <section className="p-overview">
                    <div className="p-overview__inner c-inner-primary">
                        <div className="p-client__title c-title01">
                            <h2 className="p-overview__title__text c-title01__text">Company Profile</h2>
                        </div>
                        <div className="p-overview__content">
                            <dl className="p-overview__define">
                                <div>
                                    <dt>社名</dt>
                                    <dd>株式会社DATAFLUCT（データフラクト）</dd>
                                </div>
                                <div>
                                    <dt>代表取締役<br />CEO</dt>
                                    <dd>久米村 隼人</dd>
                                </div>
                                <div>
                                    <dt>事業内容</dt>
                                    <dd>データプラットフォーム構築・運用支援事業、DX推進支援・運用支援事業、サステナブルデータビジネス事業</dd>
                                </div>
                                <div>
                                    <dt>住所</dt>
                                    <dd>
                                        <p>〒150-6207<br />
                                            東京都渋谷区桜丘町1-4<br />
                                            渋谷サクラステージ SHIBUYA サイド SHIBUYA タワー7階<br />
                                            <Gmap />
                                        </p>
                                        <p>JR「渋谷」駅新改札口徒歩1分<br />
                                            東急東横線･田園都市線、東京メトロ銀座線・半蔵門線・副都心線、京王井の頭線「渋谷」駅利用可能
                                        </p>
                                    </dd>
                                </div>
                                <div>
                                    <dt>電話番号</dt>
                                    <dd>03-6822-5590（代表）</dd>
                                </div>
                                <div>
                                    <dt>海外子会社</dt>
                                    <dd>
                                        <p>
                                            DATAFLUCT International Pte. Ltd.<br />
                                            <Link to="https://datafluct-international.com/" target="_blank">
                                                https://datafluct-international.com
                                            </Link>
                                        </p>
                                    </dd>
                                </div>
                            </dl>
                            <div className="p-overview__image">
                                <GatsbyImage
                                    image={overviewImgPath}
                                    alt="オフィスのイメージ"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="p-member">
                    <div className="p-member__inner c-inner-primary">
                        <div className="p-member__title c-title01">
                            <h2 className="p-member__title__text c-title01__text">Management Board</h2>
                        </div>
                        <ul className="p-member__list">
                            {data.allMicrocmsMember.edges.map(({ node }) => (
                                <li key={node.id}>
                                    <div className="p-member__list__image">
                                        <div className="p-member__list__image__inner">
                                            <ImgixGatsbyImage
                                                src={node.photo.url}
                                                imgixParams={{ auto: ['format', 'compress'] }}
                                                layout="fullWidth"
                                                style={{ height: "100%" }}
                                                alt={node.name}
                                                sourceWidth={node.photo.width}
                                                sourceHeight={node.photo.height}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-member__list__text">
                                        <h3 className="p-member__list__title">
                                            <span className="is-name">{node.name}</span>
                                            <span className="is-position">{node.position}</span>
                                        </h3>
                                        <p className="p-member__list__lead" dangerouslySetInnerHTML={{ __html: node.content }}></p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </section>
                <section className="p-news">
                    <div className="p-news__inner c-inner-primary">
                        <div className="p-news__title c-title01">
                            <h2 className="p-news__title__text c-title01__text">Latest updates</h2>
                            <Button path="/search" className="p-top-news__title__button c-title01__button c-button01 latest-updates-button" label="新着一覧を見る" />
                        </div>
                        <NewsListLatest3posts />
                    </div>
                </section>
                <section className="p-recruit">
                    <div className="p-recruit__inner c-inner-primary">
                        <div className="p-client__title c-title01">
                            <h2 className="p-recruit__title__text c-title01__text">Recruitment Information</h2>
                        </div>
                        <div className="p-recruit__content">
                            <div className="p-recruit__left">
                                <p className="p-recruit__catchcopy">社会に新しい価値を<br />
                                    生み出し続ける<br />
                                    プロダクトを<br />
                                    一緒に作ってみませんか</p>
                            </div>
                            <div className="p-recruit__right">
                                <p className="p-recruit__lead">創業2年で20を超えるプロダクトを生み出した圧倒的なスピード力。<br />
                                    大企業、スタートアップ出身の多様性のある優秀なメンバーが揃うDATAFLUCTだから成し遂げられたことだと考えています。移り行く社会情勢に常にアンテナを張り、「今、データサイエンスは／DATAFLUCTは、何ができるか」を考えつづけること。そしてその発想をプロダクトとして完成させ、世に送り出すこと。DATAFLUCTには、フルスピードでその一連の動きを実現させるための仲間が揃っています。</p>
                                <p className="p-recruit__lead">フルリモートで生産性の高い組織であるために、各自がOKR（Objectives and Key Results）を設定し、組織・個人の方向性とタスクを明確化しています。</p>
                                <p className="p-recruit__lead">リモート体制や上下関係のない“自由”な環境の中で、責任感を持って自律的に働くことが求められます。また年齢や性別に寄らずお互いを褒めあう文化が根付いており、プロジェクトを活性化させるアイデアや発想を出すことが常に歓迎されます。</p>
                                <div className="p-recruit__button">
                                    <Button external path="https://datafluct.notion.site/DATAFLUCT-34cc05e1b3fe477495ddd9a027c76c92" className="c-button02" label="詳しく見る" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="COMPANY"
            description=""
            pathname=""
        />
    )
}

export const query = graphql`
    query {
        allMicrocmsClient {
            edges {
                node {
                    id
                    logo {
                        url
                        width
                        height
                    }
                    name
                }
            }
        }
        allMicrocmsMember {
            edges {
                node {
                    name
                    id
                    memberId
                    content
                    photo {
                        width
                        url
                        height
                    }
                    position
                }
            }
        }
        overviewPc: file(relativePath: {eq: "pages/company/overview_img01.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        overviewSp: file(relativePath: {eq: "pages/company/overview_img01-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`
